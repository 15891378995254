import React from 'react'
import { withTheme } from '@emotion/react'

import GlobalCss from './GlobalCss.styles'
import Forms from './Forms.styles'
import Quizes from './Quizes.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <Forms />
    <Quizes />
  </>
))

export default GlobalStyles
