import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      .main-nav {
        .leshen-link,
        .primary-link,
        .leshen-link:visited,
        .primary-link:visited {
          color: #f9f9f9 !important; // fixes bug visited link bug from 15.9.3
        }

        .subnav {
          .leshen-link,
          .leshen-link:visited {
            color: #404040 !important; // fixes bug visited link bug from 15.9.3
          }
        }
      }
    `}
  />
))

export default GlobalCss
